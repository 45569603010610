/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:680926b4-8424-4ae7-a97a-b60ca6bd44a0",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_K17YdqtHl",
    "aws_user_pools_web_client_id": "25ntnqe5pv4bqao0thvgvsrqa1",
    "oauth": {
        "domain": "improa001smshistfe41e10d69-41e10d69-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://smsportal-us-east-2.improcom.com",
        "redirectSignOut": "https://smsportal-us-east-2.improcom.com",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "improA001SmsHistoryFE",
            "endpoint": "https://jaiif6s7t8.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
