import './App.css';
import {API, Auth} from 'aws-amplify';
// import {withAuthenticator} from "@aws-amplify/ui-react";
import React from 'react';

import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner} from 'reactstrap';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
} from 'reactstrap';

import {MessageList} from 'react-chat-elements'


function signOut() {
    Auth.signOut()
        .then(data => console.log(data))
        .catch(err => console.log(err))
}


class Conversation extends React.Component {

    state = {
        modalState: false,
        missedTransactions: [],
        detailsResult: null,
        displayProgressBar: false,
        actionType: "details"
    };

    toggleProgressBar = this.toggleProgressBar.bind(this);
    handleProcessing = this.handleProcessing.bind(this);


    toggleProgressBar() {
        this.setState({
            modalState: !this.state.modalState,
            displayProgressBar: true
        });
    }

    handleProcessing(event) {
        this.toggleProgressBar();


        //processing sent sms
        let chatItems = [];
        this.props.data.sent_smss.forEach(
            (sms_record) => {  //{sms_id: "10000000000", sending_date: "2021-06-17T22:52:08Z", recipient: "+19177572167", sms_text: "Hello-1"}
                if (sms_record.recipient === this.props.phoneNumber) {
                    const replicas_right = {
                        position: 'right',
                        type: 'text',
                        text: sms_record.sms_text,
                        date: new Date(sms_record.sending_date),
                    };
                    chatItems.push(replicas_right)
                }
            }
        );

        //processing received sms
        this.props.data.unread_smss.forEach(
            (sms_record) => {  //{sms_id: "10000000000", sending_date: "2021-06-17T22:52:18Z", sender: "+19177572167", sms_text: "Reply-1"}
                if (sms_record.sender === this.props.phoneNumber) {
                    const replicas_left = {
                        position: 'left',
                        type: 'text',
                        text: sms_record.sms_text,
                        date: new Date(sms_record.sending_date),
                    };
                    chatItems.push(replicas_left)
                }
            }
        );

        //sort elements
        let sortedchatItems = chatItems;
        sortedchatItems.sort((a, b) => new Date(a.date) - new Date(b.date));

        let messageList = (<MessageList
            className='message-list'
            lockable={true}
            toBottomHeight={'100%'}
            dataSource={sortedchatItems}/>)

        // create a new "State" object without mutating
        // the original State object.
        const newState = Object.assign({}, this.state, {
            detailsResult: messageList,
            displayProgressBar: false,
            actionType: "processing",
            modalState: true
        });

        // store the new state object in the component's state
        this.setState(newState);
    }


    render() {
        let modalInformation;
        let modalDetails;

        if (this.state.displayProgressBar) {
            modalInformation = <div align="center"><Spinner color="primary"/></div>
        } else {
            modalInformation = this.state.detailsResult;
        }

        return (
            <div>
                <Button color="link" onClick={this.handleProcessing}>{this.props.phoneNumber}</Button>

                <Modal isOpen={this.state.modalState} toggle={this.toggleProgressBar}>
                    <ModalHeader toggle={this.toggleProgressBar}>{this.props.phoneNumber}</ModalHeader>
                    <ModalBody>
                        {modalInformation}
                        {modalDetails}
                        {/*{JSON.stringify(this.props.data)}*/}
                    </ModalBody>
                    <ModalFooter>
                        {/*<Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}*/}
                        <Button color="secondary" onClick={this.toggleProgressBar}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

class ListOfAllMessages extends React.Component {
    render() { // sent_smss={ this.state.listOfMessages.sent_smss} unread_smss={this.state.listOfMessages.unread_smss}

        let
            arrRecipients = [];

        this.props.sent_smss.forEach((product) => {
            if (arrRecipients.includes(product.recipient
            )) {
                arrRecipients.push(product.recipient);
            }
        })

        this.props.unread_smss.forEach((product) => {
            if (!arrRecipients.includes(product.sender)) {
                arrRecipients.push(product.sender);
            }
        })

        return (
            <div>
                <h3>History</h3>
                {arrRecipients.map((t) => <Row className="text-right"><Col> <Conversation phoneNumber={t} data={this.props}> </Conversation>
                </Col></Row>)}
            </div>
        );
    }

}


class App extends React.Component {


    state = {
        authUser: false,
        listOfMessages: "",
        dropDownOpen: false
    };

// async function callAPI() {
//   const user = await Auth.currentAuthenticatedUser()
//   const token = user.getSignInUserSession.idToken.getJwtToken
//   // const tok = user.getSignInUserSession().idToken.getJwtToken()
//   console.log ({ token })
//   const requestInfo = {
//     headers: { Authorization: token }
//   }
//   const data = await API.get("", '/hello', requestInfo)
//   console.log({ data })
// }

    componentDidMount() {
        Auth.currentAuthenticatedUser()
            // .then( user => this.setState({ authUser: user } )
            .then(user => this.setState({authUser: user}))
            .catch(err => console.log(err))
    }


    getHistory = () => {
        // console.log(this.state.authUser);
        let iToken = this.state.authUser.getSignInUserSession().idToken.getJwtToken();
        const requestInfo = {
            headers: {Authorization: iToken},
            body: {
                "username": this.state.authUser.username,
                "password": "12345678",
                "last_id": "",
                "last_sent_id": "",
                "installid": "notInUse",
                "uniqueid": "notInUse"
            }
        }
        API.post('improA001SmsHistoryFE', '/fetch-history', requestInfo)
            .then(data => {
                    this.setState({listOfMessages: data})
            }
            )
            .catch(err => console.log(err))
    }


    render() {
        const authenticatedUser = () => {
            Auth.federatedSignIn();
        }

        let listOfMessages;
        if (this.state.listOfMessages.date !== undefined) {
            listOfMessages = <ListOfAllMessages sent_smss={this.state.listOfMessages.sent_smss}
                                                unread_smss={this.state.listOfMessages.unread_smss}/>;
        }
        ;

        let loginLogoutSection, buttonGetHistory
        if (!this.state.authUser) {
            loginLogoutSection = <Button outline color="primary" onClick={authenticatedUser}>Sign In</Button>
        } else {
            loginLogoutSection = <Button outline color="primary" onClick={signOut}>Sign Out</Button>
            buttonGetHistory = <Button outline color="primary" onClick={this.getHistory}>Get History</Button>
        }

        const toggle = () => this.setState({dropDownOpen: !this.state.dropDownOpen});

        return (
            <div className="App">
                <header class="App-header">
                            <Navbar color="light" light expand="md">
                                <NavbarBrand href="/">Improcom messaging portal</NavbarBrand>
                                <NavbarToggler onClick={toggle}/>
                                <Collapse isOpen={this.state.dropDownOpen} navbar>
                                    <Nav className="mr-auto" navbar>
                                        <NavItem>
                                            {buttonGetHistory}
                                        </NavItem>
                                        {/*<UncontrolledDropdown nav inNavbar>*/}
                                        {/*    <DropdownToggle nav caret>*/}
                                        {/*        Options*/}
                                        {/*    </DropdownToggle>*/}
                                        {/*    <DropdownMenu right>*/}
                                        {/*        <DropdownItem>*/}
                                        {/*            Option 1*/}
                                        {/*        </DropdownItem>*/}
                                        {/*        <DropdownItem>*/}
                                        {/*            Option 2*/}
                                        {/*        </DropdownItem>*/}
                                        {/*        <DropdownItem divider />*/}
                                        {/*        <DropdownItem>*/}
                                        {/*            Reset*/}
                                        {/*        </DropdownItem>*/}
                                        {/*    </DropdownMenu>*/}
                                        {/*</UncontrolledDropdown>*/}
                                        <NavItem>
                                            {loginLogoutSection}
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                            </Navbar>

                    <Container>
                        {listOfMessages}
                    </Container>
                </header>
            </div>
        );
    }
}

// export default withAuthenticator(App);
export default App;